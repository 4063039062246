import { createContext, useEffect, useMemo, useState } from "react";
import { getApi } from "services/api";

const defaultBranding = {
    name: 'Default',
    abbreviatedName: 'D',
    mainSiteUrl: '#!',
    primaryColor: '#f9df51',
    secondaryColor: '#093458',
};

export const BrandingContext = createContext({ branding: defaultBranding, setBranding: () => {} });

export default function BrandingProvider(props) {
    const [branding, setBranding] = useState(defaultBranding);

    useEffect(() => {
        getApi('api/branding').then(res => res.data).then(setBranding)
    }, [])

    const contextValue = useMemo(() => ({
        branding,
        setBranding
    }), [branding])

    return <BrandingContext.Provider value={contextValue}>
        {props.children}
    </BrandingContext.Provider>
}